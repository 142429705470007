
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class InputWithLabel extends Vue {
  @Prop()
  value!: string

  @Prop()
  size?: string

  @Prop({ default: null })
  labelcolor?: null | string

  @Prop()
  placeholder?: string

  @Prop({ default: false })
  enableEnterSubmit?: boolean

  get classObject(): { [key: string]: boolean } {
    const { size } = this
    return {
      'input-with-label__input__size-default': !size,
      'input-with-label__input__size-block': size === 'block',
    }
  }

  get setStyle(): { [key: string]: string } {
    let labelcolor = 'var(--color-gray-1)'
    switch (this.labelcolor) {
      case 'black':
        labelcolor = 'var(--color-black)'
        break
      case 'blue':
        labelcolor = 'var(--color-blue)'
        break
    }

    return {
      '--label-color': labelcolor,
    }
  }

  private updateValue(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value
    this.$emit('input', inputValue)
  }

  private enterSubmit(event: KeyboardEvent) {
    if (!this.enableEnterSubmit) return
    // 日本語入力時のenterはsubmit対象外
    if (event.keyCode === 13) {
      this.$emit('enter-event')
    }
  }
}
