





































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import InputWithLabel from '@/components/molecules/v3/InputWithLabel.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
    CheckboxSquare,
    InputWithLabel,
    SelectBase,
  },
})
export default class ModalStudentAdd extends Mixins(ModalBaseMethod) {
  @Prop()
  perPage?: number

  @Prop()
  items!: []

  @Prop()
  fields!: []

  @Prop()
  checkboxs?: []

  @Prop()
  links?: []

  @Prop()
  showHeaderCheckbox?: boolean

  @Prop()
  multipleLines?: []

  @Prop()
  images?: []

  @Prop()
  gradeOptions?: []

  @Prop()
  checkAll?: boolean

  @Prop()
  schoolOptions?: []

  @Prop()
  attributeOptions?: []

  @Prop({ default: 1 })
  currentPage?: number

  @Prop({ default: 0 })
  count?: number

  @Watch('checkAll')
  onItemsCheckAll() {
    if (this.checkAll == true) {
      this.headerCheckboxChecked['id'] = true
    } else {
      this.headerCheckboxChecked['id'] = false
    }
  }

  @Watch('items')
  onItemsChanged(newItems: any) {
    if (!this.checkboxs || !this.showHeaderCheckbox) return
    this.checkboxs.forEach((key: string) => {
      this.switchHeadCheckbox(key, newItems)
    })
  }

  private isMobile = window.innerWidth <= 768
  private studentParams: { [key: string]: string } = {
    nickname: '',
    studentCode: '',
    schoolSelectedData: '',
    gradeSelectedData: '',
    attributeSelectedData: '',
  }

  private resetStudentParams() {
    this.studentParams = {
      nickname: '',
      studentCode: '',
      schoolSelectedData: '',
      gradeSelectedData: '',
      attributeSelectedData: '',
    }
  }

  private headerCheckboxChecked = this.getHeaderCheckboxChecked()

  private changeAllCheckbox(key: string, value: boolean) {
    this.items.forEach((item: any) => {
      item[key].checked = value
    })
  }

  private changeHeadCheckbox(key: string) {
    if (!this.showHeaderCheckbox) return
    this.switchHeadCheckbox(key, this.items)
  }

  private switchHeadCheckbox(key: string, items: any[]) {
    const itemChecked = items.map((item: any) => {
      return item[key].checked
    })
    this.headerCheckboxChecked[key] = !itemChecked.includes(false)
  }

  private getHeaderCheckboxChecked() {
    const headerCheckboxChecked: { [key: string]: boolean } = {}
    if (!this.checkboxs || !this.showHeaderCheckbox) return headerCheckboxChecked
    this.checkboxs.forEach((key: string) => {
      headerCheckboxChecked[key] = false
    })
    return headerCheckboxChecked
  }

  private getCell(value: string): string {
    return `cell(${value})`
  }

  private getHeader(value: string): string {
    return `head(${value})`
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.$emit('paginate', page)
  }

  private searchStudent(): void {
    this.$emit('search-student', this.studentParams)
  }

  private save() {
    this.$emit('save-student', this.items)
    this.resetStudentParams()
  }

  private cancel() {
    this.$emit('hide-modal')
  }
}
